import React from "react"
import { useStaticQuery, graphql } from "gatsby"

import Img from "gatsby-image"

const ImagesData = (props) => {
/*
astronautImage: file(relativePath: { eq: "gatsby-astronaut.png" }) {
        childImageSharp {
          fluid(maxWidth: 300) {
            ...GatsbyImageSharpFluid
          }
        }
      },

      logoImage: file(relativePath: { eq: "agneya_logo_transparent-203x150.png" }) {
        childImageSharp {
          fixed(width: 130) {
            ...GatsbyImageSharpFixed
          }
        }
      },
*/

  const data = useStaticQuery(graphql`
    query {
      logoImage: file(relativePath: { eq: "agneya_logo_transparent-247x179.png" }) {
        childImageSharp {
          fixed(width: 130) {
            ...GatsbyImageSharpFixed
          }
        }
      },
      agneyaCollageImage: file(relativePath: { eq: "agneya_collage.png" }) {
        childImageSharp {
          fluid(maxWidth: 964) {
            ...GatsbyImageSharpFluid
          }
        }
      },
      agneyaProductsNightImage: file(relativePath: { eq: "agneya_products_night.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 533) {
            ...GatsbyImageSharpFluid
          }
        }
      },
      agneyaProductsNight2Image: file(relativePath: { eq: "agneya_products_night_2.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1123) {
            ...GatsbyImageSharpFluid
          }
        }
      },
      agneyaProductsShravanRakshabandhanImage: file(relativePath: { eq: "agneya_products_shravan_rakshabandhan.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 798) {
            ...GatsbyImageSharpFluid
          }
        }
      },
      agneyaProductsShravanImage: file(relativePath: { eq: "agneya_products_shravan.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 798) {
            ...GatsbyImageSharpFluid
          }
        }
      },
      agneyaProductsShravanDeepPujanImage: file(relativePath: { eq: "agneya_products_shravan_deep_pujan.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 798) {
            ...GatsbyImageSharpFluid
          }
        }
      },
      niranjan1Image: file(relativePath: { eq: "niranjan_std.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 300) {
            ...GatsbyImageSharpFluid
          }
        }
      },
      niranjan2Image: file(relativePath: { eq: "niranjan_usb.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 300) {
            ...GatsbyImageSharpFluid
          }
        }
      },
      samai1Image: file(relativePath: { eq: "samai_wm.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 300) {
            ...GatsbyImageSharpFluid
          }
        }
      },
      samai2Image: file(relativePath: { eq: "samai_brass.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 300) {
            ...GatsbyImageSharpFluid
          }
        }
      },
      lamanDiwaImage: file(relativePath: { eq: "laman_diva.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 300) {
            ...GatsbyImageSharpFluid
          }
        }
      },
      niranjanSamaiImage1: file(relativePath: { eq: "niranjan_samai1.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 300) {
            ...GatsbyImageSharpFluid
          }
        }
      },
      niranjanSamaiImage2: file(relativePath: { eq: "niranjan_samai2.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 300) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)
/*
  let normalizedProps = props
  if (props.fluid && props.fluid.presentationWidth) {
    normalizedProps = {
      ...props,
      style: {
        ...(props.style || {}),
        maxWidth: props.fluid.presentationWidth,
        margin: "0 auto",
      },
    }
  }
*/
  if(props.imgName == 'logo') {
    return <Img fixed={data.logoImage.childImageSharp.fixed} className={props.className} height={props.height} alt={props.alt} title={props.title} />
  } else if(props.imgName == 'agneya_collage') {
    return <Img fluid={data.agneyaCollageImage.childImageSharp.fluid} className={props.className} width={props.width} alt={props.alt} title={props.title} />
  } else if(props.imgName == 'agneya_products_night') {
    return <Img fluid={data.agneyaProductsNightImage.childImageSharp.fluid} className={props.className} width={props.width} alt={props.alt} title={props.title} />
  } else if(props.imgName == 'agneya_products_night_2') {
    return <Img fluid={data.agneyaProductsNight2Image.childImageSharp.fluid} className={props.className} width={props.width} alt={props.alt} title={props.title} />
  } else if(props.imgName == 'agneya_products_shravan_rakshabandhan') {
    return <Img fluid={data.agneyaProductsShravanRakshabandhanImage.childImageSharp.fluid} className={props.className} width={props.width} alt={props.alt} title={props.title} />
  } else if(props.imgName == 'agneya_products_shravan') {
    return <Img fluid={data.agneyaProductsShravanImage.childImageSharp.fluid} className={props.className} width={props.width} alt={props.alt} title={props.title} />
  } else if(props.imgName == 'agneya_products_shravan_deep_pujan') {
    return <Img fluid={data.agneyaProductsShravanDeepPujanImage.childImageSharp.fluid} className={props.className} width={props.width} alt={props.alt} title={props.title} />
  } else if(props.imgName == 'niranjan_1') {
    return <Img fluid={data.niranjan1Image.childImageSharp.fluid} className={props.className} alt={props.alt} title={props.title} />
  } else if(props.imgName == 'niranjan_2') {
    return <Img fluid={data.niranjan2Image.childImageSharp.fluid} className={props.className} alt={props.alt} title={props.title} />
  } else if(props.imgName == 'samai_1') {
    return <Img fluid={data.samai1Image.childImageSharp.fluid} className={props.className} alt={props.alt} title={props.title} />
  } else if(props.imgName == 'samai_2') {
    return <Img fluid={data.samai2Image.childImageSharp.fluid} className={props.className} alt={props.alt} title={props.title} />
  } else if(props.imgName == 'laman_diwa') {
    return <Img fluid={data.lamanDiwaImage.childImageSharp.fluid} className={props.className} alt={props.alt} title={props.title} />
  } else if(props.imgName == 'niranjan_samai1') {
    return <Img fluid={data.niranjanSamaiImage1.childImageSharp.fluid} className={props.className} alt={props.alt} title={props.title} />
  } else if(props.imgName == 'niranjan_samai2') {
    return <Img fluid={data.niranjanSamaiImage2.childImageSharp.fluid} className={props.className} alt={props.alt} title={props.title} />
  //} else if(props.imgName == 'astronaut') {
  //  return <Img fluid={data.astronautImage.childImageSharp.fluid} className={props.className} alt={props.alt} title={props.title} />
  }
}

export default ImagesData
