import { Link } from "gatsby"
import PropTypes from "prop-types"
import React, { useContext } from "react"

import { GlobalStateContext, GlobalDispatchContext } from "../context/global_context_provider"

import ImagesData from "./images"

import './css/style.css'

const Header = ({ siteTitle }) => {
  const state = useContext(GlobalStateContext);
  const dispatch = useContext(GlobalDispatchContext);
  const activateMenu = (pageId) => {
    dispatch({type: 'CURRENT_PAGE', page: pageId});
  }

  return (
    <header className="container">
      <div className="row justify-content-sm-center">
        <div className="col-2">
          <Link to="/">
            <ImagesData imgName="logo" className="img-responsive mb-0" height="130px" alt="Agneya Special Solar Products" title="Solar Niranjan"/>
          </Link>
        </div>
        <div className="col-2"></div>
        <div className="col-6 align-self-center text-center">
          <h2>Agneya Products</h2>
        </div>
        <div className="col-2"></div>
      </div>
      <div className="row">
        <nav className="navbar navbar-expand-md navbar-light static-top">
          <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNavDropdown" aria-controls="navbarNavDropdown" aria-expanded="false" aria-label="Toggle navigation">
            <span className="navbar-toggler-icon"></span>
          </button>
          <div className="collapse navbar-collapse ml-1" id="navbarNavDropdown">
            <ul className="navbar-nav ml-0">
              <li className={state.page === 'home' ? 'nav-item active' : 'nav-item'} id='home'>
                <Link className="nav-link" to="/" onClick={() => activateMenu('home')}>Home <span className="sr-only">{state.page === 'home' ? '(current)' : ''}</span></Link>
              </li>
              <li className={state.page === 'products' ? 'nav-item dropdown active' : 'nav-item dropdown'} id='products'>
                <a href="#" className="nav-link dropdown-toggle" id="productsLabel" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" rel="noopener noreferrer">
                  Products <span className="sr-only">{state.page === 'products' ? '(current)' : ''}</span>
                </a>
                <div className="dropdown-menu" aria-labelledby="productsLabel">
                  <Link to="/agneya_special" className="dropdown-item" onClick={() => activateMenu('products')}>Agneya Special</Link>
                  <Link to="/home_decor" className="dropdown-item" onClick={() => activateMenu('products')}>Home Decor</Link>
                  <Link to="/outdoor_utility" className="dropdown-item" onClick={() => activateMenu('products')}>Outdoor Utility</Link>
                </div>
              </li>
              <li className={state.page === 'gallery' ? 'nav-item active' : 'nav-item'} id='gallery'>
                <Link className="nav-link" to="/gallery" onClick={() => activateMenu('gallery')}>Gallery <span className="sr-only">{state.page === 'gallery' ? '(current)' : ''}</span></Link>
              </li>
              <li className={state.page === 'contact_us' ? 'nav-item active' : 'nav-item'} id='contact_us'>
                <Link className="nav-link" to="/contact_us" onClick={() => activateMenu('contact_us')}>Contacts Us <span className="sr-only">{state.page === 'contact_us' ? '(current)' : ''}</span></Link>
              </li>
            </ul>
          </div>
        </nav>
      </div>
    </header>
  )
}

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
